// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/dedan/projects/superdays/landing/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-as-treat-js": () => import("/Users/dedan/projects/superdays/landing/src/pages/as-treat.js" /* webpackChunkName: "component---src-pages-as-treat-js" */),
  "component---src-pages-download-js": () => import("/Users/dedan/projects/superdays/landing/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-help-js": () => import("/Users/dedan/projects/superdays/landing/src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("/Users/dedan/projects/superdays/landing/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-receive-gift-js": () => import("/Users/dedan/projects/superdays/landing/src/pages/receive-gift.js" /* webpackChunkName: "component---src-pages-receive-gift-js" */),
  "component---src-pages-success-js": () => import("/Users/dedan/projects/superdays/landing/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/dedan/projects/superdays/landing/.cache/data.json")

